import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';

import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import LetsChat from 'src/Components/ServiceComponents/LetsChat';
import {
  BlueprintBg,
  Columns,
  LinkWrapper,
  Title,
  Paragraph,
} from 'src/Components/ServiceComponents/Options';
import SEO from 'src/Components/SEO';

const Cover = styled.div`
  background: rgba(0, 0, 0, 0.25);

  position: absolute;

  width: 100%;
  height: 100%;

  z-index: 2;
`;

const DebrisField = styled.div`
  border: 0.125rem solid grey;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 2rem;
  position: relative;

  height: 40vh;
`;

const DebrisFieldImage = styled.div`
  .debris-bg {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
  }
`;

const LotsOfSatellite = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.5rem;
  text-align: center;

  position: relative;
  z-index: 3;

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 2.5rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const LotsOfSatelliteMini = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 300;
  font-size: 1rem;
  text-align: center;

  margin-bottom: 1rem;

  position: relative;
  z-index: 3;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

const CustomHeroText = styled(Hero.Text)`
  box-sizing: border-box;
  padding-right: 0px;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-right: 196px;
  }

  @media only screen and (min-width: 1024px) {
    padding-right: 352px;
  }

  @media only screen and (min-width: 1200px) {
    padding-right: 512px;
  }
`;

function Analytics(): JSX.Element {
  return (
    <>
      <SEO
        title="Operations services | Saber Astronautics"
        description="Space operations require innovation, flexibility and a keen eye for detail. Saber has the expertise and experience for your mission success, safety and security."
        url="https://www.saberastro.com/operations"
      />
      <Layout>
        <Hero.Wrapper>
          <StaticImage
            className="hero-image"
            src="../images/analytics/stars.png"
            alt="operations background"
          />
          <CustomHeroText>
            <Hero.MajorHeader style={{ textAlign: 'left' }}>
              Operations
            </Hero.MajorHeader>
            <Hero.Header style={{ textAlign: 'left' }}>
              <Base.Yellow>
                No satellite too small, no constellation too big. Our Responsive
                Space Operations Center will support your mission.
              </Base.Yellow>
            </Hero.Header>
            <Hero.LogoWrapper>
              <StaticImage
                className="logo"
                src="../images/analytics/tarot.png"
                alt="operations tarot stuff"
              />
            </Hero.LogoWrapper>
          </CustomHeroText>
        </Hero.Wrapper>
        <Container.Dark>
          <Base.Gap />
          <DebrisField>
            <LotsOfSatelliteMini>
              Tens of thousands of tracked objects
            </LotsOfSatelliteMini>
            <LotsOfSatelliteMini>
              Hundreds of thousands of untracked objects
            </LotsOfSatelliteMini>
            <LotsOfSatellite>
              <Base.Yellow>Don't leave it up to chance</Base.Yellow>
            </LotsOfSatellite>
            <DebrisFieldImage>
              <StaticImage
                className="debris-bg"
                src="../images/analytics/debris.jpg"
                alt="Debris field"
                style={{ width: '100%' }}
                objectFit="cover"
              />
            </DebrisFieldImage>
            <Cover />
          </DebrisField>
          <Base.Gap />
          <Base.Gap />
          <Base.Header style={{ textAlign: 'center' }}>
            Ensure your safety of flight with Saber Astronautics mission
            operations and integrated space traffic management services
          </Base.Header>
          <Base.Gap />
          <Base.Gap />
          <Base.Text>
            <StaticImage src="../images/analytics/mcc.jpg" alt="MCC" />
            <Base.Gap />
            <Base.Paragraph>
              Saber is writing the book on space traffic management through our
              leadership in the Pacific region of the U.S. Space Force event.
            </Base.Paragraph>
            <Base.Paragraph>
              Our operators are highly trained, able to effectively maneuver,
              monitor and respond to expected and unexpected events. We handle
              the whole range of missions, no mission is too small and no
              constellation is too large for our experienced space operators.
            </Base.Paragraph>
            {/* <StaticImage src="../images/analytics/iso.png" alt="ISO logo" />
            <Base.Paragraph>
              Saber's RSOCs are built with certified cybersecurity (ISO 27001)
              practices and use flexible infrastructure alongside the latest
              innovations, on-orbit practices, processes and technology.
            </Base.Paragraph> */}
          </Base.Text>
          <Base.Gap />
          <Base.Gap />
          <Base.Header style={{ textAlign: 'center' }}>
            Saber operations services keep your mission
          </Base.Header>
          <Base.Heading
            style={{
              background: `linear-gradient(180deg,#fec60a,#f18f01 90%)`,
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'rgba(0,0,0,0.2)',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Safe, Secure and Successful
          </Base.Heading>
          <Base.Gap />
          <Base.Gap />
        </Container.Dark>
        <BlueprintBg>
          <Columns>
            <LinkWrapper>
              <Link to="/mission-design">
                <Title>
                  Need a design? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>
                  Take a look at our Mission Design services.
                </Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/pre-flight">
                <Title>
                  Already have a plan? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>Take a look at our Pre-Flight services.</Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/diagnostics">
                <Title>
                  Diagnostics <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph style={{ margin: 0 }}>
                  Take a look at our Diagnostics services.
                </Paragraph>
              </Link>
            </LinkWrapper>
          </Columns>
        </BlueprintBg>
        <LetsChat
          image={
            <StaticImage
              className="background-image"
              src="../images/letschat4.jpg"
              alt="Lets chat background"
            />
          }
        />
      </Layout>
    </>
  );
}

export default Analytics;
