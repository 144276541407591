import styled, { keyframes } from 'styled-components';

const transformTime = '1s';

const enterAnim = keyframes`
  0% {
    filter: blur(5px);
    opacity: 0;
  }

  100% {
    filter: blur(0px);
    opacity: 1;
  }
`;

export const Cover = styled.div`
  background: linear-gradient(
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.4) 25%,
    rgba(0, 0, 0, 0.15) 100%
  );
  position: absolute;
  z-index: 4;
  height: 100%;
  width: 100%;
`;

export const Text = styled.div`
  animation: ${enterAnim} ${transformTime} ease-in;

  margin: 3rem 1rem 1rem 1rem;

  max-width: 1300px;
  width: 100%;

  z-index: 5;

  @media only screen and (min-width: 500px) {
    margin: 3rem;
  }

  a {
    color: ${({ theme }) => theme.secondary300};
    display: inline-flex;
    align-items: center;

    font-weight: normal;
  }
`;

export const Info = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 1.125rem;
  font-weight: normal;

  line-height: 1.75rem;

  margin-bottom: 1.5rem;
`;

export const Header = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 300;
  font-size: 1rem;

  line-height: 1.25rem;

  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1.75rem;
    line-height: 3rem;
  }
`;

export const MajorHeader = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.5rem;

  line-height: 2.5rem;
  margin-bottom: 0.5rem;

  text-align: center;

  .for-space {
    font-weight: bold;
    font-size: 3rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 0.75rem;

    .for-space {
      font-size: 4rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 1rem;

    .for-space {
      font-size: 6rem;
    }
  }
`;

export const LogoWrapper = styled.div`
  .logo {
    position: absolute;
    left: 0;
    top: -152px;
    height: 128px;
    width: 128px;

    @media only screen and (min-width: 1024px) {
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 256px;
      width: 256px;
    }

    @media only screen and (min-width: 1200px) {
      height: 376px;
      width: 376px;
    }
  }
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.black};
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  padding-top: 76px;
  position: relative;

  width: 100%;
  min-height: 100vh;

  .hero-image {
    object-fit: cover;
    animation: ${enterAnim} ${transformTime} ease-in;
    background: ${({ theme }) => theme.black};

    position: absolute;

    transform: scale(1);

    height: 100%;
    width: 100%;

    z-index: 1;
  }
`;

export const WrapperSmall = styled(Wrapper)`
  height: 35vh;
  min-height: 35vh;

  text-align: center;
`;
